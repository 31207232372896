/* @flow */

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useBreakpoints } from "helpers/hooks/use-breakpoints";

import styles from "./styles.scss";

type Props = {
  className?: string,
  items: Array<any>,
};

const Categories = ({ items, className = "" }: Props): React$Node => {
  const { getNumberOfDisplayedItems } = useBreakpoints(styles);

  const getAmountOfDisplayedCategories = () => {
    const breakpointColumnMapping = {
      tiny: 2,
      medium: 4,
      large: 6,
    };
    return getNumberOfDisplayedItems(breakpointColumnMapping, 6);
  };

  return items.length > 0 ? (
    <div className={cn(styles.categories, className)}>
      {items
        .slice(0, getAmountOfDisplayedCategories())
        .map((x, i) => (
          <div key={i}>
            <Link
              to={{
                pathname: x.url,
                state: { hint: { type: "category", category: x } },
              }}
              className={styles.category}
            >
              {x.image &&
              <picture className={styles.image}>
                <source srcSet={`${x.image.x1}, ${x.image.x2} 2x`} />
                <img src={x.image.x1} alt={x.name} />
              </picture>}
              <span>{x.name}</span>
            </Link>
          </div>
        ))}
    </div>
  ) : null;
};

export default Categories;
