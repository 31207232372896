/* @flow */

import React, { useState } from "react";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

import { useUi, MODE } from "helpers/ui";
import { Button, Foldable, Wrapper } from "@crossroads/ui-components";
import ChevronIcon from "icons/chevron.svg";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import useBrowserLayoutEffect from "helpers/hooks/use-browser-layout-effect";

import styles from "./styles.scss";

type SubMenu = string | null;

type Props = {
  items: $ReadOnlyArray<ParentItem>,
};

export type Item = {
  +name: string,
  +url: ?string,
};

export type ParentItem = {
  +name: string,
  +url: ?string,
  +children: $ReadOnlyArray<Item>,
};

type ItemProps = {
  className: string,
  item: Item,
  children: React$Node,
  setSubMenu: SubMenu => void,
};

const ChildItem = ({
  item,
  className = styles.item,
  setSubMenu,
  children,
}: ItemProps): React$Node => {
  const location = useLocation();
  const isParent = location.pathname === item.url;
  const { setViewMode: setMode, setHamburgerOpen } = useUi();

  return (!item.url || item.url === "/") ? (
    <div className={className}>
      {children}
    </div>
  ) : (
    <Link
      to={{
        pathname: item.url,
        state: { hint: { type: "category", category: item } },
      }}
      className={className}
      onClick={() => {
        if (isParent) {
          if (setMode) {
            setMode(MODE.NORMAL);
          }
          else {
            setHamburgerOpen(false);
          }
        }

        setSubMenu(null);
      }}
    >
      {children}
    </Link>
  );
};

const Parent = (
  { item, subMenu, setSubMenu }: {
    item: ParentItem, subMenu: SubMenu, setSubMenu: SubMenu => void,
  }): React$Node => {
  const toggleSubMenu = (name: string) => setSubMenu(subMenu === null ? name : null);

  return (
    <div className={cn(styles.row, styles.parent)}>
      <Wrapper className={styles.wrapper}>
        {item.children != null &&
          <div
            className={cn(styles.parent__item,
              { [styles.open]: subMenu === item.name }
            )}
          >
            <ChildItem
              item={item}
              setSubMenu={setSubMenu}
              className={cn(styles.heading, styles.heading__small)}
            >
              {item.name}
            </ChildItem>

            {item.children != null && item.children.length > 0 &&
              <Button className={styles.actionButton} onClick={() => toggleSubMenu(item.name)}>
                <ChevronIcon
                  className={cn(
                    styles.chevron,
                    { [styles.chevronOpen]: subMenu === item.name }
                  )} />
              </Button>
            }
          </div>
        }

        <ChildItem
          setSubMenu={setSubMenu}
          item={item}
          className={cn(styles.heading, styles.heading__large)}
        >
          {item.name}
          <ChevronIcon
            className={styles.chevron} />
        </ChildItem>

        <Foldable wrapperClass={styles.items} open={subMenu === item.name}>
          {item.children !== undefined && item.children.map(child => (
            <ChildItem
              key={child.name}
              item={child}
              className={styles.item}
              setSubMenu={setSubMenu}
            >
              <div className={styles.item__heading}>
                <span>{child.name}</span>
              </div>
            </ChildItem>
          ))}
        </Foldable>
      </Wrapper>
    </div>
  );
};

const Drawer = ({ items }: Props): React$Node => {
  const [subMenu, setSubMenu] = useState<SubMenu>(null);
  const ref = React.useRef();
  const [windowInnerHeight, setWindowInnerHeight] = useState(null);

  React.useEffect(() => {
    const element = ref.current;
    disableBodyScroll(element);

    return () => {
      if (element) {
        enableBodyScroll(element);
      }
    };
  }, []);

  useBrowserLayoutEffect(() => {
    const onResize = () => {
      setWindowInnerHeight(window.innerHeight);
    };

    if (window) {
      setWindowInnerHeight();
      window.addEventListener("resize", onResize, { passive: true });
      onResize();

      return () => {
        if (window) {
          window.removeEventListener("resize", onResize);
        }
      };
    }
  });

  return (
    <div
      ref={ref}
      style={windowInnerHeight ? { "--windowInnerHeight": `${windowInnerHeight}px` } : null}
      className={styles.drawer}
    >
      {items.map(x => (
        <Parent
          key={x.name}
          item={x}
          subMenu={subMenu}
          setSubMenu={setSubMenu} />
      ))}
    </div>
  );
};

export default Drawer;
