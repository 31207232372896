/* @flow */

import React, { useState, useEffect } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { subscribeToNewsletter } from "queries";
import { addMessage, addMessageTranslated } from "@crossroads/shop-state/messages";
import { useSendMessage } from "crustate/react";
import { useClient } from "entrypoint/shared";
import { Button } from "@crossroads/ui-components/";
import { Form } from "@awardit/formaggio";
import Field from "components/Field";
import Cookies from "js-cookie";

import styles from "./styles.scss";

const SubscribeToNewsletter = (): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const client = useClient();

  const [subToNewsletter, setSubToNewsletter] = useState<any>(Cookies.get("opt-email") === "true");
  const [formState, setFormState] = useState({
    email: null,
  });

  const subscribeToNewsletterCallback = async email => {
    try {
      await client(subscribeToNewsletter, { email });
      sendMessage(addMessage("FOOTER_SUBSCRIBE_SUCCESS", "success"));
      setFormState({ email: null });
      setSubToNewsletter(true);
    }
    catch (e_) {
      sendMessage(addMessageTranslated(e_.message, "error"));
    }
  };

  useEffect(() => {
    if (subToNewsletter) {
      Cookies.set("opt-email", subToNewsletter.toString());
    }
  }, [subToNewsletter]);

  const submit = e => {
    e.preventDefault();

    if (formState.email && !subToNewsletter) {
      subscribeToNewsletterCallback(formState.email);
    }
  };

  return (
    <div className={styles.col__subscribeToNewsletter}>
      <h2 className={styles.col__header}>{t("FOOTER.NEWSLETTER.TITLE")}</h2>
      <p>{t("FOOTER.NEWSLETTER.SIGN_UP")}</p>
      <div className={styles.col__subscribeToNewsletter__form}>
        <Form
          value={(formState: any)}
          onChange={(x: any) => {
            setFormState({ ...formState, ...x });
          }}
          onSubmit={submit}
        >
          <Field
            wrapperClassName={styles.newsletterInput}
            name="email"
            type="email"
            autoComplete="email"
            label={t("ADDRESS.EMAIL")}
            variant="float"
          />

          <Button disabled={subToNewsletter} className={(styles.formButton)} type="submit">
            {t(subToNewsletter ? "FOOTER.SUBSCRIBED" : "FOOTER.SUBSCRIBE")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default SubscribeToNewsletter;
