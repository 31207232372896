/* @flow */

import React from "react";
import { Button } from "@crossroads/ui-components";
import Link from "components/Link";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  size?: "small" | "large",
  text: string,
  cta: string,
  smallImage?: { x1: string, x2: string },
  largeImage?: { x1: string, x2: string },
  link: string,
  variant?: string,
};

const ProductPromotion = ({
  text,
  cta,
  largeImage,
  smallImage,
  link,
  size = "small",
  variant,
}: Props): React$Node => {
  const defaultImage = smallImage || largeImage || { x1: "", x2: "" };

  return (
    <Link
      to={link}
      className={cn(styles.productPromotion, { [styles[variant]]: Boolean(variant) })}
    >
      <picture className={styles.image}>
        {largeImage &&
          <source
            srcSet={largeImage.x1}
            media={`(min-width: ${styles.small}px)`}
          />
        }
        {smallImage &&
          <source
            srcSet={smallImage.x1}
            media="(min-width: 0px)"
          />
        }
        <img
          alt={text}
          src={defaultImage.x1}
          className={styles.image}
        />
      </picture>
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          <span
            className={cn(styles.text, { [styles[`size__${size}`]]: Boolean(size) })}
          >
            {text && text}
          </span>
        </div>
        <Button variant="primary">{cta && cta}</Button>
      </div>
    </Link>
  );
};

export default ProductPromotion;
