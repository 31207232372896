/* @flow */

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { useBrowser } from "@awardit/react-use-browser";
import { useUi, MODE } from "helpers/ui";
import { HomeData } from "state/data";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { formatMeta } from "helpers/get-meta";
import { Wrapper } from "@crossroads/ui-components";
import Categories from "pages/Home/Categories";
import ButtonLink from "components/ButtonLink";
import ProductPromotion from "components/ProductPromotion";
import Loading from "pages/Loading";
import useBrowserDimensions from "helpers/hooks/use-browser-dimensions";
import ProductCard from "components/ProductCard";
import Slider from "components/Slider";

import styles from "./styles.scss";

const enrichImagesData = (data: any) => {
  return data.map(s => ({
    ...s,
    images: [
      { ...s.images.large, media: `screen` },
    ],
  }));
};

const Home = (): React$Node => {
  const browser = useBrowser();
  const t = useTranslate();
  const home = useData(HomeData);
  const { info } = useContext(StoreInfoContext);
  const { isDesktop } = useUi();
  const { width: browserWidth } = useBrowserDimensions();
  const {
    setHamburgerOpen,
    setSubNavOpen,
    setViewMode,
  } = useUi();

  if (home.state !== "LOADED") {
    return <Loading />;
  }

  const slideData = (Object.values(home.data): any)
    .filter(e => e && Object.prototype.hasOwnProperty.call(e, "carousel"))
    .map(c => ({
      name: c.carousel.name,
      url: c.carousel.url,
      image: c.carousel.image,
    }));

  const meta = formatMeta({
    title: info.defaultTitle,
    description: info.defaultDescription,
  });

  const getPopularProductAmount = () => {
    if (browserWidth < Number.parseInt(styles.small, 10)) {
      return 2;
    }

    if (browserWidth < Number.parseInt(styles.medium, 10)) {
      return 3;
    }

    return 4;
  };

  const heroData =
  home.data.hero.map(c => ({
    heading: c.block.heading,
    description: c.block.text,
    url: c.block.link,
    images: {
      large: {
        url: c.block.largeImage && c.block.largeImage.x1,
      },
    },
    button: {
      text: c.block.cta,
      link: c.block.link,
    },
  }));

  return (
    <div className={styles.home}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      {heroData.length > 0 &&
        <Wrapper className={styles.topBlock}>
          <section className={styles.slider}>
            <Slider slides={enrichImagesData(heroData)} />
          </section>
        </Wrapper>
      }

      <Wrapper>
        <div className={styles.popularCategories}>

          <div className={styles.sectionHeader}>
            <p className={styles.sectionTitle}>{t("HOMEVIEW.POPULAR_CATEGORIES")}</p>
            <ButtonLink
              variant="ghost"
              className={styles.cta}
              onClick={() => {
                setHamburgerOpen(true);
                setSubNavOpen(true);
                setViewMode(MODE.CATEGORIES);
              }}
            >
              {t("HOMEVIEW.SEE_ALL")}
            </ButtonLink>
          </div>

          <Categories items={slideData} className={styles.categories} />
        </div>

        {home.data.popularProducts &&
        home.data.popularProducts.items.length >= 4 ?
          <div className={styles.popularProducts}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>{t("POPULAR.POPULAR_PRODUCTS")}</p>
              {isDesktop && (
                <ButtonLink
                  variant="ghost"
                  to="/popular-products"
                >
                  {t("HOMEVIEW.SEE_ALL")}
                </ButtonLink>
              )}
            </div>
            <div className={styles.products}>
              {browser && home.data.popularProducts.items
                .slice(0, getPopularProductAmount())
                .map((p, i) => (
                  <div key={p.name} className={styles.product}>
                    <ProductCard product={p} position={i} list={t("POPULAR.POPULAR_PRODUCTS")} />
                  </div>
                ))}
            </div>
            {!isDesktop && (
              <div className={styles.buttonWrapper}>
                <ButtonLink
                  variant="ghost"
                  to="/popular-products"
                >
                  {t("HOMEVIEW.SEE_MORE_FEATURED_PRODUCTS")}
                </ButtonLink>
              </div>
            )}
          </div> :
          null}

        {home.data.block2FullWidth ?
          <div className={styles.single}>
            <ProductPromotion {...home.data.block2FullWidth.block} />
          </div> :
          null}

        {home.data.block1Left &&
        home.data.block1Right ?
          <div className={styles.double}>
            <ProductPromotion {...home.data.block1Left.block} variant="inline" />
            <ProductPromotion {...home.data.block1Right.block} variant="inline" />
          </div> :
          null}
      </Wrapper>
    </div>
  );
};

export default Home;
