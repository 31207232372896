/* @flow */

import React, { useState, useEffect, useRef } from "react";
import { useClient } from "entrypoint/shared";
import { searchSuggestions as searchSuggestionsQuery } from "queries";
import { debounce } from "diskho";

type SearchSuggestion = {
  name: string,
  urlKey: string,
  image?: string,
  price?: number,
  type: "product" | "brand" | "category",
};

const SEARCH_SUGGESTION_SORT_WEIGHT = {
  product: 0,
  brand: 1,
  category: 2,
};

const transformSearchResult = (acc, curr) => {
  acc.push({
    name: curr.name,
    urlKey: curr.url,
    image: curr.attributes.image?.src,
    price: curr.price?.incVat,
    type: "product",
  });

  if (curr.attributes.manufacturer) {
    const brand = curr.attributes.manufacturer;

    if (!acc.some(a => a.name === brand)) {
      acc.push({
        name: curr.attributes.manufacturer,
        urlKey: "/brand/" + encodeURIComponent(brand),
        type: "brand",
      });
    }
  }

  if (curr.categories.length > 0) {
    for (const category of curr.categories) {
      if (!acc.some(a => a.name === category.name)) {
        acc.push({
          name: category.name,
          urlKey: category.url,
          type: "category",
        });

        break;
      }
    }
  }

  return acc;
};

const sortSuggestions = (a, b) => {
  if (a.type !== b.type) {
    return SEARCH_SUGGESTION_SORT_WEIGHT[a.type] - SEARCH_SUGGESTION_SORT_WEIGHT[b.type];
  }

  return a.name.localeCompare(b.name);
};

const useSearchSuggestions = (searchQuery: string): Array<SearchSuggestion> => {
  const client = useClient();
  const [searchSuggestions, setSearchSuggestions] = useState<Array<SearchSuggestion>>([]);

  const search = async query => {
    try {
      const result = await client(searchSuggestionsQuery, { query, pageSize: 2 });
      const items: Array<SearchSuggestion> = result.searchSuggestions.items
        .reduce(transformSearchResult, [])
        .sort(sortSuggestions);

      setSearchSuggestions(items);
    }
    catch {
      setSearchSuggestions([]);
    }
  };

  const searchDebounced = useRef(debounce(query => {
    if (!query || query.length < 3) {
      setSearchSuggestions([]);
      return;
    }

    search(query);
  }, 250)).current;

  useEffect(() => {
    searchDebounced(searchQuery);
  }, [searchQuery]);

  return searchSuggestions;
};

export default useSearchSuggestions;
