/* @flow */

import type { CmsPage } from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

  type Data =
    | { state: "INIT" }
    | { state: "LOADING" }
    | { state: "LOADED", data: CmsPage };

export type CmsResponse =
    Response<typeof CMS_RESPONSE, ?CmsPage> | EffectErrorMessage;

export type CmsRequest = {
    tag: typeof CMS_REQUEST,
    url: string,
  };

export const CMS_RESPONSE: "response/cms" = "response/cms";
export const CMS_REQUEST: "request/cms" = "request/cms";

export const load = (url: string): CmsRequest => {
  return {
    tag: CMS_REQUEST,
    url,
  };
};

export const CmsModel: Model<Data, {
  url: string}, CmsRequest | CmsResponse> = {
    id: "cms",
    init: ({ url }) =>
      updateData(
        { state: "LOADING" },
        load(url)
      ),
    update: (state: Data, msg) => {
      switch (msg.tag) {
        case EFFECT_ERROR:
          throw new Error("Route load failed");
        case CMS_RESPONSE:
          if (msg.data && (state.state === "LOADING")) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;
        default:
      }
    },
  };

