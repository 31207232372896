/* @flow */

import type { RouterHistory as History, Location } from "react-router";
import { useCallback, useEffect, useRef } from "react";

type Ref<T> = {|current: T|};

type OnPush<T> = (history: History, location: Location) => T;
type OnPop<T> = (history: History, location: Location, metadata: ?T) => void;

export const useOnNavigation = <T>(history: History, onPush: OnPush<T>, onPop: OnPop<T>) => {
  const lastKey = useRef(history.location.key);
  const log = useRef([]);

  useEffect(() => history.listen(location => {
    const { current: currentKey } = lastKey;

    if (history.action === "PUSH") {
      // Ensure we actually are pushing after the current key, clear from the
      // end until we find currentKey
      if (currentKey) {
        for (let i = log.current.length - 1; i >= 0; i--) {
          if (log.current[i].key !== currentKey) {
            log.current.pop();

            break;
          }
        }
      }

      log.current.push({
        key: lastKey.current,
        data: onPush(history, location),
      });
    }
    else if (history.action === "POP") {
      let metadata = null;

      // Find the metadata for the key
      for (let i = log.current.length - 1; i >= 0; i--) {
        if (log.current[i].key === location.key) {
          metadata = log.current[i];

          break;
        }
      }

      onPop(history, location, metadata ? metadata.data : null);
    }

    lastKey.current = location.key;
  }), [lastKey, log, history, onPush, onPop]);
};

const scrollToTop = () => {
  const previousPosition = window.scrollY;

  window.scrollTo(0, 0);

  return previousPosition;
};

const scrollToMetadata = (history, location, previousPosition: ?number) => {
  window.scrollTo(0, previousPosition || 0);
};

export const usePreserveScrollPosition = (history: History): void =>
  useOnNavigation(history, scrollToTop, scrollToMetadata);

// TODO: Maybe a way to reset the height in a callback?
export const usePreserveHeightOnNavigation = (
  history: History,
  container: Ref<?HTMLElement>,
  defaultHeight: string | null = null
): void => {
  const getHeight = useCallback(
    () => container.current ? container.current.clientHeight : null,
    [container]
  );

  const restoreHeight = useCallback((history: History, location: Location, prevHeight: ?number) => {
    if (container.current) {
      // Null is how you reset, so let the default actually be null
      container.current.style.minHeight = typeof prevHeight === "number" ?
        prevHeight + "px" :
        (defaultHeight: any);
    }
  }, [container, defaultHeight]);

  useOnNavigation(history, getHeight, restoreHeight);
};
