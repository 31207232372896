/* @flow */

import type { Product as ProductType, ProductCardProduct, ProductCategory } from "shop-state/types";
import type { Option } from "@crossroads/ui-components";

import React, { useState, useEffect, useMemo } from "react";
import { useAnalytics } from "context/analytics";
import { useData } from "crustate/react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { useGetProductMeta } from "helpers/get-meta";
import useFormat from "helpers/hooks/use-format";
import { QuoteData } from "state/data";
import { Link } from "react-router-dom";
import ProductList from "components/ProductList";
import Breadcrumbs from "components/Breadcrumbs";
import PriceSplit from "components/PriceSplit";
import {
  ProductViewMedia,
  Button,
  AutoExpand,
  AddToCart,
  productDefaults,
  inStock,
  getAttributesConfigurable,
  getSelectedConfigurable,
  Wrapper,
  getPrice,
} from "@crossroads/ui-components";
import { ColorSelect } from "components/ProductOptions";
import { pointsPriceByID, isPointsOnly } from "helpers/points";
import useBreadcrumbLinks from "helpers/hooks/use-breadcrumb-links";
import CloseIcon from "icons/close.svg";

import styles from "./styles.scss";

type ProductProps = {
  product: ProductType,
};

type HintProductProps = {
  product: ProductCardProduct,
};

type StructuredCategory = {
  name: string,
  url: string,
  children: Array<{
    name: string,
    url: string,
  }>,
};

const CustomCloseIcon = (): React$Node => {
  return <CloseIcon className={styles.customCloseIcon} />;
};

const AddToCartBtn = ({
  loading = false, outOfStock,
}: { loading?: boolean, outOfStock: boolean,
}): React$Node => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const addingToCart = quoteState.state === "ADDING_ITEM";

  return (
    <Button
      className={styles.addToCartButton}
      variant="primary"
      loading={addingToCart || loading}
      disabled={outOfStock}
      type="submit"
    >
      {outOfStock ? t("PRODUCT.OUT_OF_STOCK") : t("PRODUCT.ADD_TO_CART")}
    </Button>
  );
};

const mapProductCategories = (
  categories: $ReadOnlyArray<ProductCategory>
): Array<StructuredCategory> => {
  const structuredCategories: Array<StructuredCategory> = [];

  for (const category of categories) {
    if (category.parent) {
      let parent = structuredCategories.find(c => c.url === category.parent?.url);

      if (parent) {
        parent.children.push({
          name: category.name,
          url: category.url,
        });
      }
      else {
        parent = {
          name: category.parent?.name || "",
          url: category.parent?.url || "",
          children: [{
            name: category.name,
            url: category.url,
          }],
        };

        structuredCategories.push(parent);
      }
    }
    else {
      structuredCategories.push({
        name: category.name,
        url: category.url,
        children: [],
      });
    }
  }

  return structuredCategories;
};

const Product = ({ product }: ProductProps): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const analytics = useAnalytics();
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const [selected, setSelected] = useState<Option>(productDefaults(product));
  const selectedItem = getSelectedConfigurable(selected, configAttributes);
  const { price } = (selectedItem ?? product);
  const brand = product.attributes.manufacturer;
  const { manufacturer } = product.attrDescriptions;
  const meta = useGetProductMeta(product, t);
  const outOfStock = !inStock(product, selected);
  const { smallImage, largeImage } = product.attributes;
  const { attributes: { showDiscount } } = product;
  const pointsPrice = pointsPriceByID(product.pointsPrices, "vanilla");
  const pointsOnly = isPointsOnly(pointsPrice);
  const { msrp } = (selectedItem ? selectedItem.product.attributes : product.attributes);
  const categories = useMemo(() => mapProductCategories(product.categories), [product]);
  const breadcrumbLinks = useBreadcrumbLinks({ fallbackCategories: categories });
  const { formatPrice } = useFormat();
  const discount = (showDiscount === true && typeof msrp === "number") ?
    Math.floor((msrp - price.incVat) / msrp * 100) : 0;

  const [currentImage, setCurrentImage] = useState({
    smallImage: smallImage ? { x1: smallImage.x1, x2: smallImage.x2 } : null,
    largeImage: largeImage ? { x1: largeImage.x1, x2: largeImage.x2 } : null,
  });

  useEffect(() => {
    analytics.registerProductDetailsView({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    },
    product.price.incVat,
    location.state?.list,
    location.state?.position);
  }, []);

  // Change image when selecting option
  useEffect(() => {
    if (selectedItem) {
      const a = selectedItem.product.attributes;

      if (a) {
        setCurrentImage({
          smallImage: a.smallImage ? { x1: a.smallImage.x1, x2: a.smallImage.x2 } : null,
          largeImage: a.largeImage ? { x1: a.largeImage.x1, x2: a.largeImage.x2 } : null,
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    setCurrentImage({
      smallImage,
      largeImage,
    });
  }, [smallImage, largeImage]);

  const onAdd = p => {
    if (selectedItem) {
      const incVat = getPrice(product, selected, "incVat");
      const exVat = getPrice(product, selected, "exVat");

      analytics.registerModifyCart({
        ...selectedItem.product,
        sku: product.sku,
        qty: 1,
        price: { incVat, exVat, vat: incVat - exVat },
        categories: p.categories,
      }, 1, incVat);
    }
    else {
      analytics.registerModifyCart({
        ...p,
        qty: 1,
      }, 1, p.price.incVat);
    }
  };

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <div className={styles.split}>
        <div className={styles.right}>
          {discount ? discount > 0 && discount < 100 &&
          <div className={styles.discount}>
            <strong>{`-${discount}%`}</strong>
          </div> : null
          }
          <header className={styles.header__inner}>
            <div className={styles.top}>
              <Link
                to={{
                  pathname: `/brand/${encodeURIComponent(brand)}`,
                  state: { hint: { category: { name: brand } } },
                }}
                className={styles.brand}
              >
                {brand}
              </Link>
              <h1 className={styles.name}>{product.name}</h1>
              <PriceSplit
                className={styles.price} pointsPrice={pointsPrice} price={price} />
              {typeof msrp === "number" && msrp > product.price.incVat && showDiscount === true &&
                <span className={styles.msrp}>{t("PRODUCT.MSRP")} {formatPrice(msrp)}</span>
              }
            </div>
          </header>
          <section className={styles.addToCart}>

            <AddToCart
              product={product}
              selected={selected}
              setSelected={setSelected}
              qty={1}
              templates={{
                configurable: ColorSelect,
              }}
              onAdd={onAdd}
            >
              <AddToCartBtn outOfStock={outOfStock} />
            </AddToCart>
          </section>

          <section className={styles.productSection}>
            <span>{t("PRODUCT.DESCRIPTION")}</span>
            <div
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
              className={styles.description}
            />
          </section>

          {manufacturer && manufacturer.icon && manufacturer.description &&
            <section className={styles.productSection}>
              <div className={styles.manufacturer}>
                <span>{t("PRODUCT.ABOUT_BRAND", { brand: manufacturer.title })}</span>
                <p>{manufacturer ? manufacturer.description : ""}</p>
                <Link
                  to={{
                    pathname: `/brand/${encodeURIComponent(brand)}`,
                    state: { hint: { category: { name: brand } } },
                  }}
                  className={styles.goto}
                >
                  {t("PRODUCT.GO_TO_MANUFACTURER", { brand: manufacturer.title })}
                </Link>
              </div>
            </section>
          }
        </div>

        <div className={styles.left}>
          <ProductViewMedia
            CloseIcon={CustomCloseIcon}
            alt={product.name}
            currentImage={currentImage}
            gallery={product.gallery}
            location={location}
            galleryLocation="bottom"
          />
          <div className={styles.badges}>
            {pointsOnly && <span>{t("PRODUCT.POINTS_ONLY")}</span>}
          </div>
        </div>
      </div>

      <AutoExpand>
        &nbsp;
        <div className={styles.lists}>
          <div className={styles.relatedList}>
            {product.relatedProducts.items.length > 0 &&
              <ProductList
                heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
                products={product.relatedProducts.items}
                productsPerRow={2}
              />
            }
          </div>

          <div className={styles.historyList}>
            {product.crossSellProducts.items.length > 0 &&
              <ProductList
                heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
                products={product.crossSellProducts.items}
                productsPerRow={2}
              />
            }
          </div>
        </div>
      </AutoExpand>
    </Wrapper>
  );
};

export const HintProduct = ({ product }: HintProductProps): React$Node => {
  const location = useLocation();
  const brand = product.attributes.manufacturer;
  const image = product.attributes.largeImage ||
  product.attributes.smallImage ||
  location.state?.image || { x1: "", x2: "" };
  const breadcrumbLinks = useBreadcrumbLinks();

  return (
    <Wrapper className={styles.hintWrapper}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <div className={styles.split}>

        <div className={styles.right}>
          <header className={styles.header__inner}>
            <div className={styles.top}>
              <Link
                to={{
                  pathname: `/brand/${encodeURIComponent(brand)}`,
                  state: { hint: { category: { name: brand } } },
                }}
                className={styles.brand}
              >
                {brand}
              </Link>
              <h1 className={styles.name}>{product.name}</h1>
            </div>
          </header>

          <div className={styles.dummyContent}>
            <div className={styles.top} />
            <div className={styles.middle} />
            <div className={styles.bottom} />
          </div>
        </div>

        <div className={styles.left}>
          <ProductViewMedia
            alt={product.name}
            currentImage={{
              largeImage: image,
              smallImage: image,
            }}
            gallery={[]}
            location={location}
            galleryLocation="bottom"
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Product;
