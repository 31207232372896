/* @flow */

export type Config = {|
  +host: string,
  +port: number,
  apiHost: {|
    server: string,
    client: string,
    headers: Array<string>,
  |},
  +googleAnalytics: Array<string>,
  proxyHost?: {|
    cookieDomainRewrite: string,
    target: string,
  |},
  +altapay: {|
    +src: string,
  |},
  cspUri?: string | null,
  +FACEBOOK_PIXEL_ID: string
|};

const config: Config = {
  host: "localhost",
  port: 9179,
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forwarded-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: ["UA-54817209-2", "G-943HEHRFWG", "G-61KP7ERE2R", "G-1HQP20GS4H"],
  FACEBOOK_PIXEL_ID: "965135766975493",
  altapay: {
    src: "https://awardit.altapaysecure.com/checkout/v1/js/AltaPaySDK.js",
  },
  cspUri: null,
};

module.exports = config;
