/* @flow */

import { FACEBOOK_PIXEL_ID } from "../config";

// https://developers.facebook.com/docs/facebook-pixel/advanced/
const event = (name: string, options: any) => {
  if (window.fbq) {
    window.fbq("track", name, options);
  }
};

const init = () => {
  if (window.fbq) {
    // Grant consent
    window.fbq("consent", "grant");
    // Initialize pixel
    window.fbq("init", FACEBOOK_PIXEL_ID);
    window.fbq("track", "PageView");
  }
};

const revoke = () => {
  if (window.fbq) {
    window.fbq("consent", "revoke");
  }
};

const fbq = {
  event,
  init,
  revoke,
};

export default fbq;
