/* @flow */

import React from "react";
import { SuccessView } from "@crossroads/shop-views";
import { useAnalytics } from "context/analytics";
import { useClient } from "entrypoint/shared";
import { OrderData } from "state/data";
import { useData } from "crustate/react";
import { load as loadOrder } from "state/order";
import { subscribeToNewsletter } from "queries";
import useFormat from "helpers/hooks/use-format";
import CheckIcon from "icons/checkmark-success.svg";
import ChatIcon from "icons/chat.svg";
import PhoneIcon from "icons/phone.svg";

const Success = (): React$Node => {
  const orderData = useData(OrderData);
  const { formatPrice } = useFormat();
  const analytics = useAnalytics();

  return (
    <SuccessView
      orderData={orderData}
      useClient={useClient}
      analytics={analytics}
      formatPrice={formatPrice}
      additionalField="CUSTOMER_SERVICE"
      loadOrder={loadOrder}
      mapComponent={null}
      subscribeToNewsletter={subscribeToNewsletter}
      CheckIcon={<CheckIcon style={{ color: "#3f921b" }} />}
      ChatIcon={<ChatIcon style={{ height: "32px", width: "32px" }} />}
      PhoneIcon={<PhoneIcon style={{ height: "32px", width: "32px" }} />}
    />
  );
};

export default Success;
