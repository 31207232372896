/* @flow */

import React from "react";

import cn from "classnames";
import { useFormField } from "@awardit/formaggio";
import { Input, Checkbox, Foldable, SearchableDropdown, DropdownItem } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";

import CheckboxIcon from "icons/checkbox.svg";
import styles from "./styles.scss";

type InputProps = {
  name: string,
  alwaysValidate?: boolean,
};

type CheckboxProps = {
  ...React$ElementProps<"input">,
  className?: string,
  children: React$Node,
};

type DropdownProps = {
  ...React$ElementProps<"input">,
  name: string,
  items: Array<{ code: string, name: string }>,
  label?: string,
};

const Field = ({ alwaysValidate = false, ...props }: InputProps): React$Node => {
  const t = useTranslate();
  const { submitted, dirty, errors, inputProps } = useFormField(props.name, "");

  const inputErrors = (submitted || dirty) || alwaysValidate === true ?
    errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <Input {...props} {...inputProps} errors={inputErrors} />
  );
};

export default Field;

export const CheckboxField = ({ children, name, ...props }: CheckboxProps): React$Node => {
  const t = useTranslate();
  const fieldProps = useFormField(name, "");

  const inputErrors = fieldProps.submitted || fieldProps.dirty ?
    fieldProps.errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          {...props}
          {...fieldProps.inputProps}
          className={styles.checkbox}
          CheckedComponent={CheckboxIcon}
          value={fieldProps.inputProps.value}
          name={name}
        />
        <label htmlFor={fieldProps.inputProps.id} style={{ paddingLeft: "0.7em" }}>
          {children}
        </label>

      </div>
      <Foldable className={styles.errors} open={inputErrors.length > 0}>
        {inputErrors.map(x => <span key={x}>{x}</span>)}
      </Foldable>
    </>
  );
};

export const SearchableDropdownField = ({
  alwaysValidate = false,
  items,
  label,
  ...props }: DropdownProps): React$Node => {
  const t = useTranslate();
  const { submitted, dirty, errors, inputProps } = useFormField(props.name, "");

  const inputErrors = (submitted || dirty) || alwaysValidate === true ?
    errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <div className={styles.searchableDropdown}>
      <SearchableDropdown
        {...props}
        {...inputProps}
        value={inputProps.value.toString()}
        errors={inputErrors}
        onChange={x => inputProps.onChange((({ target: { value: x } }): any))}
      >
        {items.map(c => (
          <DropdownItem key={c.code} value={c.code}>
            {c.name}
          </DropdownItem>
        ))}
      </SearchableDropdown>
      {label &&
        <label
          className={cn(
            styles.searchableDropdownLabel,
            { [styles.searchableDropdownLabel__active]: inputProps.value.toString().length > 0 }
          )}
        >
          {label}
        </label>
      }
    </div>
  );
};

