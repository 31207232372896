/* @flow */

import React from "react";
import { Wrapper, Spinner } from "@crossroads/ui-components";
import styles from "./styles.scss";

const Loading = (): React$Node => {
  return <Wrapper className={styles.loading}><Spinner /></Wrapper>;
};

export default Loading;
