/* @flow */

import type { ProductCardProduct } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "@crossroads/shop-state/utils";
import { updateData } from "crustate";

export type Popular = {|
  totalCount: number,
  items: Array<ProductCardProduct>,
|};

export type BlockHero = {
  heading: string,
  subHeading: string,
  splash: string,
  text: string,
  // smallImage: { x1: string, x2: string },
  largeImage: { x1: string, x2: string },
  cta: string,
  link: string,
  modifier: string,
};

export type BlockCategory = {
  text: string,
  smallImage: { x1: string, x2: string },
  largeImage: { x1: string, x2: string },
  cta: string,
  link: string,
  modifier: string,
};

export type CarouselItem = {
  image?: { x1: string, x2: string },
  name: string,
  url: string,
};

export type Home = {
  hero: $ReadOnlyArray<{block: BlockHero}>,
  block1Left: {
    block: BlockCategory,
  },
  block1Right: {
    block: BlockCategory,
  },
  block2FullWidth: {
    block: BlockCategory,
  },
  c1: {
    carousel?: CarouselItem,
  },
  c2: {
    carousel?: CarouselItem,
  },
  c3: {
    carousel?: CarouselItem,
  },
  c4: {
    carousel?: CarouselItem,
  },
  c5: {
    carousel?: CarouselItem,
  },
  c6: {
    carousel?: CarouselItem,
  },
  c7: {
    carousel?: CarouselItem,
  },
  c8: {
    carousel?: CarouselItem,
  },
  popularProducts: Popular,
  featuredProducts: {
    category: {
      name: string,
      url: string,
      products: {
        items: Array<ProductCardProduct>,
      },
    },
  },
};

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: Home }
  | { state: "ERROR", error: string };

export type HomeResponse = Response<typeof HOME_RESPONSE, ?Home>;

export type HomeRequest = {
  tag: typeof HOME_REQUEST,
  type: "home",
};

export const HOME_RESPONSE: "response/home" = "response/home";
export const HOME_REQUEST: "request/home" = "request/home";

export const HomeModel: Model<Data, { }, HomeRequest | HomeResponse> = {
  id: "home",
  init: () =>
    updateData(
      { state: "LOADING", type: "home" },
      { tag: HOME_REQUEST, type: "home" }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case HOME_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
