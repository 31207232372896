/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { HomeResponse } from "state/home";

import { HOME_REQUEST, HOME_RESPONSE } from "state/home";
import { home } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const data = await client(home, undefined, { cache: false });
      const { hero1, hero2, hero3 } = data;

      return ({
        tag: HOME_RESPONSE,
        data: {
          ...data,
          hero: [hero1, hero2, hero3].reduce((acc, curr) => {
            if (curr) {
              acc.push(curr);
            }

            return acc;
          }, []),
        },
      }: HomeResponse);
    },
    subscribe: { [HOME_REQUEST]: true },
  });
};

export default registerClient;
