/* @flow */

import type { CookieOptions } from "js-cookie";

import { useState, useCallback, useEffect } from "react";
import { useBrowser } from "@awardit/react-use-browser";
import Cookies from "js-cookie";

const readCookie = (key: string, initial: string, options?: CookieOptions): string => {
  try {
    const serialized = Cookies.get(key);

    return serialized ? serialized : initial;
  }
  catch {
    Cookies.remove(key, options);

    return initial;
  }
};

const useCookie = (
  key: string,
  initial: string,
  options?: CookieOptions
): [string, (value: string) => void, boolean] => {
  const browser = useBrowser();
  const [item, setInnerValue] = useState(initial);
  const [cookieRead, setCookieRead] = useState(false);

  useEffect(() => {
    if (browser) {
      setInnerValue(readCookie(key, initial, options));
      setCookieRead(true);
    }
  }, [browser, setInnerValue, initial, key, options]);

  const setValue = useCallback((value: string) => {
    setInnerValue(value);

    Cookies.set(key, value, options);
  }, [key, options, setInnerValue]);

  return [item, setValue, cookieRead];
};

export default useCookie;
