/* @flow */

import type { FilterLocation as Location } from "shop-state/types";

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import usePrevious from "helpers/hooks/use-previous";

import {
  getInputFilters,
  getInputSort,
  getPage,
  getSearchFromFilter } from "@crossroads/shop-state/filter";

export const locationWithDefaults = (
  location: Location): Location => {
  const productFilters = getInputFilters(location);
  const productSort = getInputSort(location);
  const page = getPage(location);

  return {
    ...location,
    search: getSearchFromFilter(productFilters, productSort, page),
  };
};

export const useUpdateProductList = (load: Location => void) => {
  const { location } = useHistory();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (prevLocation && location.search !== prevLocation.search) {
      load(locationWithDefaults(location));
    }
  }, [location, prevLocation]);
};
