/* @flow */

import React from "react";
import { Link as RouterLink } from "react-router-dom";

type Props = {
  to: string,
  children: React$Node,
};

const Link = ({ to, ...props }: Props): React$Node => {
  return (
    to && to.charAt(0) !== "/" ?
      <a {...props} href={to} rel="noopener noreferrer" target="_blank">
        {props.children}
      </a> :
      <RouterLink {...props} to={to}>
        {props.children}
      </RouterLink>
  );
};

export default Link;
