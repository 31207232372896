/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import { Button as ButtonComponent } from "@crossroads/ui-components";

type Props = {
  children: React$Node,
  to?: string,
};

const ButtonLink = ({ to = "", children, ...props }: Props): React$Node => {
  if (to.length > 0) {
    return (
      <Link to={to} style={{ textDecoration: "none" }}>
        <ButtonComponent {...props}>{children}</ButtonComponent>
      </Link>
    );
  }

  return <ButtonComponent {...props}>{children}</ButtonComponent>;
};

export default ButtonLink;
