/* @flow */

import useBrowserDimensions from "helpers/hooks/use-browser-dimensions";

type UseBreakpointsReturnValue = {
  isCurrentBreakpoint: (breakpoint: string) => boolean,
  getNumberOfDisplayedItems: (
    breakpointColumnMapping: { [string]: number },
    defaultAmount: number
  ) => number,
};

export const useBreakpoints = (styles: any): UseBreakpointsReturnValue => {
  const { width: browserWidth } = useBrowserDimensions();

  const isCurrentBreakpoint = (breakpoint: string) =>
    (browserWidth < Number.parseInt(styles[breakpoint], 10));

  const getNumberOfDisplayedItems = (
    breakpointColumnMapping: { [string]: number },
    defaultAmount: number
  ) => {
    const currentBreakpoint = Object.keys(breakpointColumnMapping).find(isCurrentBreakpoint);

    return !currentBreakpoint ? defaultAmount : breakpointColumnMapping[currentBreakpoint];
  };

  return {
    isCurrentBreakpoint,
    getNumberOfDisplayedItems,
  };
};
